import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ContactAppointmentMail extends Vue {
  public style = `<style>body { font-family:  {FONT}; font-size:12px; background: #F5F5F5;}
  .wrapper {background: #FFF;margin: auto;width: 690px;}
  td{font-family:  {FONT}; font-size:12px;}
  tr.header {background: #ffffff;}
  td.logo{text-align: center;padding: 30px 5px; background: #ffffff;}
  td.content{padding: 0px 40px;padding-bottom:40px;padding-top: 45px;border-bottom: 6px solid {BUTTON_BACK_COLOR};}
  .title { font-weight: bold; }            
  table { border-collapse:collapse; }
  tr.group td {padding: 12px 5px;font-weight: bold;font-size: 13px;height: 22px;line-height: 20px;}
  td { padding: 2px 5px; }
  td.name { border-bottom: 1px solid #E6E6E6;padding-top: 10px;}
  td.value { border-bottom: 1px solid #E6E6E6;padding-top: 10px; }
  .style1 {font-size: 22px;color: {BUTTON_BACK_COLOR};text-transform: uppercase;font-weight: normal;letter-spacing: 1px;}
  #last1, #last2{border-bottom:2px solid gray;}</style>
  `;

  get customStyle() {
    const style = this.style;
    if (this.buttonBackColor) {
      style.replace('{BUTTON_BACK_COLOR}', this.buttonBackColor);
    }
    if (this.font) {
      style.replace('{FONT}', this.font);
    }
    return style;
  }

  @Prop({ default: '' }) private FIELD_SITE_LINK!: string;
  @Prop({ default: '' }) private LOGO!: string;

  // field visitor
  @Prop({ default: '' }) private FIELD_SUBJECT!: string;
  @Prop({ default: '' }) private FIELD_FIRSTNAME!: string;
  @Prop({ default: '' }) private FIELD_NAME!: string;
  @Prop({ default: '' }) private FIELD_PHONENUMBER!: string;
  @Prop({ default: '' }) private FIELD_EMAIL!: string;
  @Prop({ default: '' }) private FIELD_CONTENT!: string;

  // field property
  @Prop({ default: '' }) private FIELD_OBJECTID!: string;
  @Prop({ default: '' }) private FIELD_PROPERTY_APPOINTMENT_TEXT!: string;
  @Prop({ default: '' }) private FIELD_PROPERTY_GOAL!: string;
  @Prop({ default: '' }) private FIELD_PROPERTY_STREET!: string;
  @Prop({ default: '' }) private FIELD_PROPERTY_HOUSENUMBER!: string;
  @Prop({ default: '' }) private FIELD_PROPERTY_ZIP!: string;
  @Prop({ default: '' }) private FIELD_PROPERTY_CITY!: string;
  @Prop({ default: '' }) private FIELD_PROPERTY_TYPE!: string;

  // field agency
  @Prop({ default: '' }) private FIELD_SITE_ADDRESS_TITLE!: string;
  @Prop({ default: '' }) private FIELD_SITE_STREET_HOUSENUMBER!: string;
  @Prop({ default: '' }) private FIELD_SITE_CITY_ZIP!: string;
  @Prop({ default: '' }) private FIELD_SITE_PHONE!: string;
  @Prop({ default: '' }) private FIELD_SITE_EMAIL!: string;

  @Prop({ default: '#d50a51' }) private buttonBackColor!: string;
  @Prop({ default: 'Arial,Helvetica,sans-serif' }) private font!: string;
}
